a {
  color: #023f75;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  color: #023f75;
}

.navbar-item.is-tab.is-active {
  border-bottom-color: #023f75;
  color: #023f75;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: #023f75;
}

.navbar.is-transparent .navbar-dropdown a.navbar-item.is-active {
  color: #023f75;
}
